@import '../../../assets/global/main';

.EmptyContent {
  &__inner {
    @include standard-layout-column;
    padding: 50px;
  }

  &__div {
    font-weight: bold;
    text-align: center;
  }
}
