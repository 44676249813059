@import '../../../../assets/global/main';

.Contact {
  margin-bottom: 60px;

  &__inner {
    @include standard-layout-column;
    display: flex;

    @include for-size-large {
      flex-direction: column;
    }
  }

  &__infoContainer {
    display: flex;
    width: 35%;
    padding: 15px;
    flex-direction: column;

    @include for-size-large {
      width: 100%;
    }
  }

  &__formContainer {
    flex: 1 1 auto;
    padding: 15px;

    @include for-size-large {
      width: 100%;
    }
  }

  &__infoTitle {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    color: $brand-dark-purple;
  }

  &__adressLine {
    margin-bottom: 5px;
    color: $basic-body-color;
  }

  &__infoSeparator {
    width: 100%;
    margin: 20px 0;
    border: none;
    height: 1px;
    background-color: #e9e9f4;
  }
}

.contactList :global(.contactList) {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contactList :global(.contactList__title) {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #2c2c51;
}

.contactList :global(.contactList__element) {
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #696969;
  font-size: 15px;
  margin-bottom: 5px;
}

.contactList :global(.contactList__separator) {
  height: 1px;
  width: 100%;
  border: none;
  background-color: #eeebf6;
  margin: 15px 0;
}