@import '~/assets/global/main';

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  width: 16px;
  opacity: 0.5;
  margin-left: 8px;
}

.tooltip {
  position: absolute;
  left: 50%;
  top: auto;
  bottom: 31px;
  z-index: 2;
  padding: 10px 14px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(47, 46, 55, 0.2), 0 1px 3px 0 rgba(30, 32, 59, 0.11);
  font-size: 13px;
  font-weight: 400;
  color: $basic-headlings-color;
  display: none;
  transform: translate(-50%, 0);
  min-width: 35ch;

  .container:hover & {
    display: block;
  }
}