@import '~/assets/global/main';

.container {
  @include standard-layout-column;
  padding: 20px 0 60px;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr;

  &.twoColumns {
    grid-template-columns: repeat(2, 1fr);

    @include for-size-big {
      grid-template-columns: 1fr;
    }
  }

  &.threeColumns {
    grid-template-columns: repeat(3, 1fr);

    @include for-size-large {
      grid-template-columns: repeat(2, 1fr);
    }

    @include for-size-big {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 60px 20px;
  }

  @media screen and (max-width: 479px) {
    padding: 60px 2vw 30px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 33.33%;
  grid-row-gap: 16px;

  @include for-size-large {
    flex-basis: 100%;
  }

  @include for-size-big {
    padding: 0 15px;
  }
}

.item__content {
  display: flex;
  align-items: flex-start;
  grid-column-gap: 16px;
}

.item__logo {
  padding-left: 80px;
  display: flex;
}


.item__main {
  min-height: 100px;
  padding-bottom: 16px;
  color: #666;
  font-size: 18px;
  line-height: 1.6em;
  font-style: italic;
  letter-spacing: 0em;

  @include for-size-big {
    min-height: 0px;
    font-size: 18px;
  }
}


.item__icon {
  width: 64px;
  height: 64px;
  flex: 0 0 auto;
}

.item__author {
  min-height: 100px;
  color: #666;
  font-size: 16px;
  line-height: 1.6em;
  text-align: right;
  letter-spacing: 0em;

  @include for-size-big {
    min-height: 0px;
    font-size: 13px;
  }
}