@import '~/assets/global/main';

.extraSpace {
  @include use-predefined-background;

  flex: none;
  height: var(--desktop-height);

  @include for-size-large {
    height: var(--tablet-height);
  }

  @include for-size-medium {
    height: var(--mobile-height);
  }
}