@import '~/assets/global/main';

.container {
  display: flex;
  align-items: center;
  gap: 24px;
  max-height: 64px;

  @include for-size-large {
    gap: 12px;
  }
}

.icon {
  max-height: 56px;
  max-width: 56px;

  @include for-size-large {
    max-height: 40px;
    max-width: 40px;
  }
}

.title {
  font-weight: 600;
  line-height: 1.25em;
  font-size: 24px;
  margin: 0;

  @include for-size-large {
    font-size: 18px;
  }

  @include for-size-small {
    font-size: 14px;
  }
}