@import '~/assets/global/main';

.popup {
  @include use-predefined-background;

  display: flex;
  flex-direction: column;
  padding: 35px 45px;
  position: relative;
  pointer-events: auto;
  box-shadow: 0px 2.1946px 5.3201px 0px rgba(67, 43, 119, 0.03), 0px 7.371px 17.869px 0px rgba(67, 43, 119, 0.04),
    0px 33px 80px 0px rgba(67, 43, 119, 0.07);
  border-radius: 4px;
  overflow: auto;
  max-height: 100%;

  p {
    font-size: 14px;
    margin: 0;
  }

  &>section {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  //
  &.DarkPurple,
  &.LightPurpleGradient,
  &.LightPurple {

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .popup.LightPurpleGradient &,
  .popup.LightPurple &,
  .popup.DarkPurple & {
    filter: brightness(10);
  }
}

.container {
  position: fixed;
  z-index: 50;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  // Positions
  &.center {
    align-items: center;
  }

  &.center,
  &.bottomCenter,
  &.topCenter {
    justify-content: center;
  }

  &.topCenter,
  &.topLeft,
  &.topRight {
    margin-top: 116px;

    @include for-size-xtralarge {
      margin-top: 128px;
    }

    @include for-size-large {
      margin-top: 112px;
    }

    align-items: flex-start;
  }

  &.bottomCenter,
  &.bottomLeft,
  &.bottomRight {
    align-items: flex-end;
  }

  &.topLeft,
  &.bottomLeft {
    justify-content: flex-start;
  }

  &.topRight,
  &.bottomRight {
    justify-content: flex-end;
  }
}

.backdrop {
  pointer-events: auto;
}

.bottomOffsetMobileOnly {
  @include for-size-large {
    padding-bottom: 180px;
  }
}

.bottomOffset {
  padding-bottom: 120px;

  @include for-size-large {
    padding-bottom: 180px;
  }
}