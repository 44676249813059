@import '../../assets/global/main';

$mainNav-anchor-font-color: $brand-dark-purple;
$nested-elements-box-shadow: 0 7px 19px 3px rgba(14, 0, 40, 0.1);

.ContentMenu {

  // Menu list containers
  &__rootMenu {
    list-style: none;
    margin: 0 3px 0 0;
    padding: 0;
    display: flex;
  }

  &__nestedMenu {
    position: absolute;
    top: 47px;
    z-index: 10;
    list-style: none;
    background: #fff;
    margin: 0;
    padding: 8px 0;
    display: none;
    flex-direction: column;
    min-width: 250px;
    box-shadow: $nested-elements-box-shadow;
  }

  &__rootMenuListElement {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
  }

  &__nestedMenuListElement {

    &:hover,
    &:focus,
    &:active {
      background-color: $brand-light-gray;

      >ul {
        display: flex;
      }
    }
  }

  // Menu Anchors
  &__rootMenuItemAnchor {
    padding: 15px;
    margin: 0px 3px;
    color: $mainNav-anchor-font-color;
    align-items: center;
    display: flex;
    background-color: transparent;
    border: none;
    appearance: none;
    cursor: pointer;
    transition: background 150ms ease-in-out;

    &:hover {
      background: rgba(103, 58, 183, 0.04);
      border-radius: 5px;
    }

    >*+* {
      margin-left: 5px;
    }

    &[aria-expanded='true']+ul {
      display: flex !important;
    }
  }

  &__nestedMenuItemAnchor {
    display: flex;
    font-size: 14px;
    padding: 8px 18px;
    align-items: center;
    gap: 6px;
  }

  // Menu Anchor Labels
  &__rootMenuItemLabel {
    line-height: 1;
    display: flex;
  }

  &__rootMenuItemActiveBorder {
    height: 3px;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: -15px;
    background-color: $brand-yellow;
  }

  &__nestedMenuItemLabel {
    display: flex;
  }

  // Menu Anchor Chevrons
  &__rootMenuItemChevron {
    display: flex;
    height: 10px;
    width: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cdefs/%3E%3Cpath fill='%23401e58' d='M207 381L13 187c-10-9-10-24 0-34l22-22c10-10 25-10 34-1l155 155 155-155c9-9 24-9 34 1l22 22c10 10 10 25 0 34L241 381c-9 10-25 10-34 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.MenuItemBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  background-color: $brand-yellow;
  border-radius: 12px;
  padding: 0.5px 9px;
  text-transform: uppercase;
  color: white;
}