@import '../../../../../assets/global/main';

.NewsletterForm {
  @include standard-layout-column;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include for-size-large {
    align-items: normal;
  }

  &__inner {
    position: relative;
    padding: 10px 0 15px 0;
    display: flex;
    justify-content: center;
    width: 100%;

    @include for-size-large {
      justify-content: normal;
    }

    @include for-size-big {
      flex-direction: column;
    }
  }

  &__inputContainer {
    position: relative;
    margin-right: 8px;
    width: 100%;

    @include for-size-large {
      max-width: 415px;
    }

    @include for-size-big {
      margin-right: 0;
      margin-bottom: 20px;
      max-width: 100%;
    }
  }

  &__emailInput {
    height: 45px;
    border: 2px solid $border-medium;
    border-radius: 1000px;
    background-color: $input-background-color;
    width: 100%;
    padding: 10px 18px;
    margin-right: 15px;

    @include for-size-big {
      margin-right: 0;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }

    &::placeholder {
      color: $text-empty-state;
      font-weight: bold;
    }
  }

  &__buttonInput {
    @include soft-transition;
    appearance: none;
    height: 45px;
    border: 2px solid transparent;
    border-radius: 22px;
    cursor: pointer;
    background-color: transparent;
    padding: 0 22px;
    color: $brand-saturated-purple;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;

    @include for-size-big {
      width: 100%;
    }

    &:hover,
    &:focus,
    &:active {
      @include soft-transition;
      background-color: $brand-saturated-purple;
      color: #fff;
    }

    &.withBorder {
      border-color: $brand-saturated-purple;
      color: $brand-saturated-purple;

      &:hover,
      &:focus,
      &:active {
        border-color: $brand-saturated-purple;
        color: #fff;
      }
    }
  }

  &__formMessage,
  &__formErrorMessage {
    @extend .formMessage;
    height: unset;
    word-break: keep-all;
    white-space: nowrap;
    right: unset;
    left: 0;
    bottom: -10px;
    line-height: 1.25;

    @include for-size-large {
      justify-content: flex-end;
    }

    @include for-size-medium {
      word-break: unset;
      white-space: unset;
    }
  }

  &__formErrorMessage {
    color: #ff7878;
  }

  &__buttonSpinner {
    @extend .buttonSpinner;
  }

  &__errorMessage {
    @extend .formErrorMessage;
    max-width: 240px;
    bottom: -28px;
  }
}