@import '~/assets/global/main';

.inner {
  @include standard-layout-column;
  display: flex;

  @include for-size-large {
    flex-direction: column;
  }
}

// Content part
.contentPart {
  width: 100%;
  max-width: 552px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 24px;

  @include for-size-large {
    max-width: 100%;
  }

  &.isRightImage {
    order: 1;

    @include for-size-large {
      order: 2;
    }
  }

  &.isLeftImage {
    order: 2;

    @include for-size-large {
      order: 2;
    }
  }
}

.title {
  font-size: 26px;
  line-height: 36px;
  color: $brand-dark-purple;
  margin-bottom: 15px;
  margin-top: 0;

  @include for-size-large {
    font-size: 20px;
    line-height: 28px;
  }
}

.title.large {
  font-size: 36px;
  line-height: 48px;

  @include for-size-large {
    font-size: 30px;
    line-height: 40px;
  }

}

.kicker {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 18px;
  color: $brand-yellow;

  @include for-size-large {
    font-size: 16px;
  }
}

.ctaHolder {
  display: inline-flex;
  margin: 15px 0 30px;
}

.paragraph * {
  font-size: 16px;
  line-height: 28px;
}

.paragraph.large * {
  font-size: 18px;
  line-height: 32px;
}

.paragraph strong {
  color: $brand-dark-purple;
}

// Image part
.imagePart {
  @include unselectable;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 24px;

  >div {
    border-radius: 5px;
  }

  &.isRightImage {
    order: 2;

    @include for-size-large {
      order: 1;
    }
  }

  &.isLeftImage {
    order: 1;

    @include for-size-large {
      order: 1;
    }
  }
}

.imagePart--with-shadow {
  >div {
    box-shadow: 1px 11px 31px $shadow-color;
  }
}

.container {
  padding: 0;

  @include use-predefined-background;

  &.centerAlign {
    text-align: center;

    .contentPart {
      align-items: center;
    }
  }

  &.leftAlign {
    text-align: left;
  }

  &.rightAlign {
    text-align: right;
  }

  &.withMarginBottom {
    margin-bottom: 120px;

    .ctaHolder {
      margin-bottom: 0;
    }

    @include for-size-large {
      margin-bottom: 50px;
    }
  }
}