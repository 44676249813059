@import '~/assets/global/main';

.iconContainer {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.checkIcon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: $brand-saturated-purple;
}

.mainFeatureIcon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: $brand-saturated-purple;
  margin: 0 4px;
}

.label {
  font-size: 16px;
  line-height: 1.33;
  color: $text-normal;
  white-space: nowrap;

  @include for-size-medium() {
    white-space: normal;
  }
}

.container {
  display: inline-flex;
  align-items: center;
  gap: 12px;
}

.item {
  display: inline-flex;
}