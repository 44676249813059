@import '~/assets/global/main';

$element-box-shadow: 0 30px 60px 0 rgba(0, 11, 40, 0.14);

.SolutionsSection {
  @include standard-layout-column;
  padding: 40px 0;

  &__rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
  }

  &__solution {
    @include soft-transition;
    width: calc(33% - 32px);
    box-shadow: 1px 11px 31px #21192c1a;
    border-radius: 8px;
    background-color: #fff;
    justify-content: space-between;
    margin-bottom: 30px;
    cursor: pointer;

    &:hover,
    &:active {
      background-color: #fff;
      box-shadow: $element-box-shadow;
    }

    @include for-size-large {
      width: calc(50% - 32px);
    }

    @include for-size-medium {
      width: 100%;
      margin: auto 30px;
    }
  }

  &__solutionInner {
    position: relative;
    padding: 10px 40px 30px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  &__solutionTitle {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $brand-dark-purple;
    line-height: 1;
    margin-bottom: 10px;
  }

  &__solutionDescription {
    font-size: 16px;
    font-weight: 500;
    color: $brand-dark-purple;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__solutionCurrencySymbol {
    font-size: 40px;
    line-height: 1;
  }

  &__solutionCtaContainer {
    margin: 15px 0;

    .SolutionsSection__solutionCtaButton {
      padding: 11.5px 17px;
      width: 100%;
    }
  }

  &__solutionSymbolHolder {
    @include unselectable;
    height: 110px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    border-radius: 50%;
  }

  &__solutionPerks {
    width: 100%;
    flex: 1;

    ul {
      padding: 0;
    }

    li {
      list-style: none;
      padding-left: 30px;
      display: flex;
      padding-bottom: 12px;
      font-size: 14px;

      &:before {
        content: '';
        background-image: url('~/public/assets/icons/check.svg');
        color: $brand-dark-purple;
        font-weight: 700;
        width: 20px;
        height: 20px;
        position: absolute;
        margin-left: -30px;
      }
    }
  }
}