@import '~/assets/global/main';

.hero__inner {
  @include standard-layout-column;
  padding-top: 60px;
  display: flex;

  @include for-size-large {
    flex-direction: column;
  }
}

.hero__header {
  span {
    font-weight: 600;
    font-size: 20px;
    color: $brand-saturated-purple;
  }

  h1 {
    font-weight: 600;
    color: $brand-dark-purple;
  }
}

.hero__content {
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex: 1;

  @include for-size-large {
    padding-bottom: 0;
  }
}

.hero__mediaContainer {
  padding: 24px;
  position: relative;
  width: 50%;

  @include for-size-large {
    padding-top: 0;
    width: 100%;
  }

  .hero__video {
    margin-top: 48px;
    background: #000;
    aspect-ratio: 543/307;

    @include for-size-large {
      margin-top: 0;
      aspect-ratio: initial;
    }
  }
}

.hero__formWrapper {
  margin-top: 20px;
}

.hero__form {
  padding: 0 !important;
  align-items: flex-start !important;

  form {
    justify-content: flex-start;
  }

  button {
    width: fit-content;
  }
}