@import '../../../../assets/global/main';

.Content {

  &.Regular,
  &.Full {
    @include standard-layout-column;
    padding: 35px 15px;
  }

  &.Regular {
    max-width: $regular-content-max-width;
  }

  margin-bottom: 0;
}