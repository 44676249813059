@import '~/assets/global/main';

.title {

  &,
  >div {
    padding: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }
}


.wrapper {
  margin: 0 auto;
}