@import '../../../../assets/global/main';

.PerksTable {
  margin: 30px 0;

  &__singlePerkSymbol {
    display: flex;
  }

  &__inner {
    @include standard-layout-column;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__singlePerk {
    width: 25%;
    padding: 16px;
    margin-bottom: 35px;

    @include for-size-large {
      width: 33.33%;
    }

    @include for-size-big {
      width: 50%;
    }

    @include for-size-medium {
      width: 100%;
      padding: 10vw;
    }
  }

  &__singlePerkTitle {
    color: $brand-dark-purple;
    font-size: 18px;
    margin: 8px 0;
    line-height: 1.5;
  }

  &__singlePerkDescription {
    font-size: 15px;
    line-height: 26px;
    color: $basic-body-color;
  }

  &__PerkIcon {
    @include unselectable;
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: $brand-dark-purple;
    padding: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
}
