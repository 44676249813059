@import '~/assets/global/main';

.container {
  padding: 20px 4px 16px 16px;
  border-bottom: 1px solid $border-medium;
  position: sticky;
  top: 77px;

  @include for-size-big() {
    padding: 10px 8px 8px 16px;
    top: 70px;
  }

  background-color: $input-background-color;
  z-index: 1;
}

.title {
  font-size: 1.15em;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 1px;
  color: $brand-saturated-purple;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.price {
  font-size: 1em;
  line-height: 1.15;
  color: $text-normal;
  padding: 4px 0 12px 0;
  margin: 0;

  @include for-size-big() {
    display: none;
  }
}

.priceMobile {
  font-size: 1em;
  line-height: 1.15;
  color: $text-normal;
  padding: 4px 0 0 0;
  margin: 0;
  display: none;
  white-space: nowrap;

  @include for-size-big() {
    display: block;
  }
}

.smallCtaButton {
  font-size: 13px;
  width: fit-content;
  padding: 3px 16px;

  @include for-size-big() {
    display: none;
  }
}