@import '~/assets/global/main';

.ServicePerks {
  position: relative;

  @include conditional-bottom-margin();

  &__inner {
    @include standard-layout-column;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    justify-content: center;

    @include for-size-large {
      grid-template-columns: 1fr 1fr;
    }

    @include for-size-medium {
      grid-template-columns: 1fr;
    }
  }

  &__singlePerk {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 15px;

    @include for-size-large {
      margin-bottom: 40px;
    }

    @include for-size-medium {
      padding: 10vw;
      margin-bottom: 8px;
    }
  }

  &__singlePerkButton {
    margin: 30px 0;
    width: fit-content;

    @include for-size-large {
      margin: 20px 0 0;
    }
  }

  &__singlePerkInner {
    max-width: 300px;

    @include for-size-large {
      max-width: 100%;
    }
  }

  &__singlePerkContent {
    margin-top: 25px;
    max-width: 300px;

    @include for-size-large {
      max-width: 100%;
    }
  }

  &__singlePerkTitle {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    max-width: 66%;
    margin-bottom: 4px;
    margin-top: 0;
    color: $brand-dark-purple;
  }

  &__singlePerkDescription {
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 1rem 0;

    a {
      color: #521ec9;
    }
  }

  &__PerkIcon {
    @include unselectable;
    position: relative;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: $brand-dark-purple;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    padding: 18px;
  }

  &.twoColumns {
    align-items: center;

    .ServicePerks__inner {
      @media screen and (min-width: 767px) {
        max-width: 50%;
      }
    }

    .ServicePerks__row {
      grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
      column-gap: 100px;

      @include for-size-big {
        grid-template-columns: 1fr;
      }
    }

    .ServicePerks__singlePerkInner {
      max-width: 100%;
    }
  }

  &.singleColumn {
    .ServicePerks__row {
      grid-template-columns: 1fr;
    }
  }

  &.centerAlign {
    text-align: center;

    .ServicePerks__singlePerk {
      display: flex;
      justify-content: center;
    }

    .ServicePerks__singlePerkInner {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;
    }
  }

  &.rightAlign {
    text-align: right;

    .ServicePerks__singlePerkInner {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
    }
  }

  &.yellowIcon {
    .ServicePerks__PerkIcon {
      background-color: $brand-yellow;
    }
  }

  &.lightPurpleIcon {
    .ServicePerks__PerkIcon {
      background-color: $brand-saturated-purple;
    }
  }

  &.transparentIcon {
    .ServicePerks__PerkIcon {
      background-color: transparent;
      border: 2px solid $brand-dark-purple;
      padding: 16px;
    }
  }

  &.LightGrayBackground {
    background-color: $section-light-gray-background;
  }

  &.LightGrayPurpleBackground {
    background-color: $brand-pastel-purple;
    color: $brand-dark-purple;
  }
}