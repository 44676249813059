@import '../../../../assets/global/main';

.SlideShow {
  overflow: hidden;
  background: #f9f9f9;

  &__inner {
    @include standard-layout-column;
    padding: 175px 0;
    position: relative;

    @include for-size-large {
      padding: 100px 10px;
    }
  }

  &__sliderContainer {
    max-width: 690px;
    margin: 0 auto;
  }

  &__slideInner {
    text-align: center;
    line-height: 28px;
  }

  &__personName {
    font-size: 20px;
    color: $title-body-color;
  }

  &__personRole {
    font-size: 14px;
  }

  &__personSentence {
    margin-top: 35px;
    font-size: 20px;
    line-height: 1.5em;
  }

  &__personVisualScore {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGYAAAARCAMAAAAYJikpAAAAJ1BMVEUAAAD/zyD/zyD8zx38zx39zRz9zRv+zRz+zhz9zhz9zhv9zhz9zhw3CsU+AAAADHRSTlMAECBQYICfr7/P3+/DJdHbAAAAcUlEQVR42rXItQEDUQBDsfeZvP+64RzWVil+4lqRjS2rVMGdUS8Rd9Z3VfBm1EfElqm0rk1vJVlSN2DIcZ0BhgzjMgEcud02OHK7bUyZ98qAK8teBWzZ9+pgy6mXtfQywZbvKCGUd4Itl2oEiFULLPkEhCknu/sG2o8AAAAASUVORK5CYII=);

    background-repeat: no-repeat;
  }

  &__personScore {
    width: 102px;
    height: 17px;
    position: relative;
    margin: 16px auto 0 auto;
  }

  &__shapeOne {
    @include unselectable;
    position: absolute;
    background-image: -webkit-linear-gradient(140deg, #7d0df0 0, #0cb6e7 100%);
    width: 650px;
    height: 510px;
    transform: rotate(45deg);
    border-radius: 45px;
    top: -340px;
    opacity: 0.02;
    left: 122px;
  }

  &__shapeTwo {
    @include unselectable;
    position: absolute;
    background-image: -webkit-linear-gradient(140deg, #7d0df0 0, #0cb6e7 100%);
    width: 666px;
    height: 330px;
    left: 290px;
    transform: rotate(45deg);
    border-radius: 45px;
    top: -340px;
    opacity: 0.02;
    left: 318px;
  }

  &__decor {
    height: 0;
    display: none;

    @include for-size-large {
      display: none;
    }
  }

  &__shapeText {
    @include unselectable;
    height: 200px;
    width: 100%;

    &:before {
      content: attr(data-text);
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #f5f4fd;
      font-weight: 700;
      font-size: 200px;
      line-height: 200px;
      z-index: 0;
      text-transform: uppercase;
      top: 200px;
      opacity: 0.6;
    }
  }
}
