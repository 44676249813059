@import '~/assets/global/main';

.container {
  display: flex;
  flex-wrap: wrap;

  @include use-predefined-background;

  //
  &.DarkPurple,
  &.LightPurpleGradient,
  &.LightPurple {

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }
  }
}

.title {
  padding-top: 25px;
}

.column {
  @include standard-layout-column;
}