@import '~/assets/global/main';

.bottomSmallPerks {
  display: flex;
  padding-top: 16px;
  padding-bottom: 8px;
  flex-wrap: wrap;

  @include for-size-medium {
    margin-top: 4px;
  }

  &__single {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    position: relative;
    font-size: 13px;
    line-height: 1;
    color: $basic-body-color;

    @include for-size-large {
      padding-left: 15px;
    }

    &:first-of-type {
      padding-left: 0;

      @include for-size-large {
        padding-left: 15px;
      }
    }

    &:last-of-type {
      padding-right: 0;
    }

    img {
      margin-right: 6px;
    }
  }
}