@import '../../../../assets/global/main';

.FullWidthSection {
  margin-bottom: 80px;

  &.noBottomMargin {
    margin-bottom: 0;
  }

  &.DarkPurple {
    background-color: $brand-dark-purple;
    color: $brand-yellow;
  }

  &.Yellow {
    background-color: $brand-yellow;
    color: $brand-dark-purple;
  }

  &.LightGray {
    background-color: $section-light-gray-background;
    color: $brand-dark-purple;
  }

  &.LightGrayPurple {
    background-color: $brand-pastel-purple;
    color: $brand-dark-purple;
  }

  &__inner {
    @include standard-layout-column;
    display: flex;
    padding: 35px 0 42px;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    padding: 15px;
    text-align: center;
    max-width: 65%;

    @include for-size-big {
      max-width: 100%;
    }
  }

  &__ctaList {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: max-content;
    gap: 10px;

    @include for-size-big {
      flex-direction: column;
      width: 100%;
      margin-top: 20px;
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    flex: none;

    @include for-size-big {
      width: 100%;
      margin-top: 20px;
    }
  }


  &__ctaButtonWrapper {
    max-width: 190px;
    max-width: fit-content;
  }

  &__contentTitle {
    font-size: 28px;
    line-height: 1.3em;
    color: inherit;
    letter-spacing: -0.01em;
    text-align: center;
    margin: 0;
  }

  &__contentDescription {
    margin-top: 20px;
  }
}