@import '~/assets/global/main';

.baseSection {
  margin-bottom: 80px;

  &.noBottomMargin {
    margin-bottom: 0;
  }

  &.DarkPurpleBackground {
    background-color: $brand-dark-purple;
    color: $brand-yellow;
  }

  &.YellowBackground {
    background-color: $brand-yellow;
    color: $brand-dark-purple;
  }

  &.LightGrayBackground {
    background-color: $section-light-gray-background;
    color: $brand-dark-purple;
  }

  &.LightGrayPurpleBackground {
    background-color: $brand-pastel-purple;
    color: $brand-dark-purple;
  }

  &__inner {
    @include standard-layout-column;
    display: flex;
    padding: 35px 0 42px;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    padding: 15px;
    text-align: center;
    max-width: 65%;

    @include for-size-big {
      max-width: 100%;
    }
  }
}