.container {
  display: flex;
}

.banner {
  max-width: 100%;

  &.center {
    align-self: center;
  }

  &.left {
    align-self: flex-start;
  }

  &.right {
    align-self: flex-end;
  }

  &.withMarginBottom {
    margin-bottom: 30px;
  }
}