@import '../../../../assets/global/main';

.AboutUsPictures {
  &__inner {
    @include standard-layout-column;
  }

  &__personCollection {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @include for-size-large {
      justify-content: space-around;
      padding: 15px;
    }
  }

  &__singlePerson {
    @include unselectable;
    height: auto;
    width: 220px;
    position: relative;
    display: flex;

    &:not(:empty) {
      margin-bottom: 40px;
    }
  }

  &__singlePersonOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.85);
    width: 100%;
    padding: 15px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__singlePersonOverlayName {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 10px;
    color: $brand-dark-purple;
  }

  &__singlePersonOverlayRole {
    font-size: 14px;
    line-height: 1;
    color: $basic-body-color;
  }
}
