@import '~/assets/global/main';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4x;
  border-radius: 6px 15px 15px 6px;
  padding: 12px 26px 12px 26px;
  overflow: hidden;
}

.container p:last-of-type {
  margin: 0;
}

.container .title p {
  font-size: 1.075rem;
  font-weight: bold;
  color: $basic-body-color;
}

.details {
  margin: 8px 0;
}

.container .details p {
  font-size: 1.05rem;
  color: $basic-body-color;
  font-weight: normal;
}

.container.yellow {
  background-color: #FFF7DB;
}

.container.purple {
  background-color: $article-background-color;
}

.colorIndent {
  position: absolute;
  width: 6px;
  height: 100%;
  left: 0;
  top: 0;
}

.colorIndent.yellow {
  background-color: $brand-yellow;
}

.colorIndent.purple {
  background-color: $brand-soft;
}