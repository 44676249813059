@import '~/assets/global/main';

.container {
  @include standard-layout-column;
  padding: 60px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 991px) {
    padding: 60px 20px;
  }

  @media screen and (max-width: 479px) {
    padding: 60px 10vw 30px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-column-gap: 16px;
  color: $brand-dark-purple;
  font-size: 18px;
  line-height: 1.6em;
  letter-spacing: 0em;
}

.logo {
  padding-left: 80px;
  display: flex;
}

.quotationMark {
  width: 64px;
  height: 64px;
  flex: 0 0 auto;
}

.author {
  min-height: 70px;
  line-height: 1.6em;
  text-align: right;
  letter-spacing: 0em;

  .baseAuthorData {
    display: flex;
    flex-direction: column;
  }
}

.logoContainer {
  position: relative;
  flex: none;
  overflow: hidden;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.authorImageContainer {
  position: relative;
  flex: none;
  aspect-ratio: 1/1;
  max-width: -moz-fit-content;
  width: 100%;

  @include for-size-big {
    display: none;
  }
}

.authorImageDecoration {
  bottom: -10px;
  right: -5px;
}

/*
  Variant: simple
*/
.simple {

  .quote,
  .author {
    font-style: italic;
  }

  .quote {
    min-height: 100px;
    padding-bottom: 16px;
  }
}

/*
  Variant: section
*/
.baseSectionContent {
  max-width: none;
}

.section,
.sectionCenter {
  padding: 20px 0;

  @include for-size-large {
    padding: 10px;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 25%;
    gap: 100px;

    @include for-size-big {
      grid-template-columns: 1fr;
    }
  }

  .texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .quote {
    text-align: left;
    font-weight: 600;
    font-size: 25px;
    line-height: 1.4em;
    padding-bottom: 16px;
    min-height: 100px;

    u {
      background: linear-gradient(to right, $brand-yellow, $brand-yellow) no-repeat;
      background-size: 100% 2px;
      background-position: left bottom;
      text-decoration: none;
    }

    @include for-size-large {
      font-size: 20px;
    }
  }

  .author {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    text-align: left;

    span:first-child {
      font-weight: 600;
    }

    @include for-size-large {
      margin-top: 25px;
    }
  }
}

/*
  Variant: section center
*/
.sectionCenter {
  padding: 0;

  .content {
    grid-template-columns: 1fr;
    gap: 100px;
    padding: 0 10%;
    text-align: center;
  }

  .quote {
    text-align: center;
  }

  .authorImageContainer {
    border-radius: 100%;
    overflow: hidden;
    width: 80px;
    margin-bottom: 30px;
  }

  .texts {
    align-items: center;
  }

  .author {
    display: flex;
    margin-top: 15px;
    flex: 1;
    align-items: center;
    justify-content: center;
    min-height: auto;

    .baseAuthorData {
      display: flex;
      flex-direction: row;
      gap: 8px;
      width: 100%;

      span:first-child:after {
        content: ','
      }

      @include for-size-medium {
        flex-direction: column;
        text-align: center;
        gap: 0;

        span:first-child:after {
          content: '',
        }
      }
    }
  }
}



/*
  Variant: bubble
*/
.baseDenseBubbleSection {
  padding-top: 0;
  padding-bottom: 0;

  >div {
    padding: 10px 0;
  }
}

.baseBubbleContent {
  max-width: none;
  padding-top: 0;
  padding-bottom: 0;
}

.bubble {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: transparent;
  gap: 15px;
  padding-bottom: 0;

  @include for-size-big {
    padding: 10px;
  }

  .content {
    padding: 40px 80px;
    border-radius: 80px;
    gap: 15px;

    @include for-size-big {
      padding: 40px;
      border-radius: 40px;
    }
  }

  &.DarkPurpleBackground .content {
    background-color: $brand-dark-purple;
    color: $brand-yellow;
  }

  &.YellowBackground .content {
    background-color: $brand-yellow;
    color: $brand-dark-purple;
  }

  &.LightGrayBackground .content {
    background-color: $section-light-gray-background;
    color: $brand-dark-purple;
  }

  &.LightGrayPurpleBackground .content {
    background-color: $brand-pastel-purple;
    color: $brand-dark-purple;
  }

  .texts {
    gap: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .quotationMark {
    flex: none;
    width: 80px;

    @include for-size-large {
      display: none;
    }
  }

  .quote {
    text-align: left;

    p {
      color: inherit;
    }

    p:first-child:not(:last-child) {
      font-size: 32px;
      line-height: 1.4;
      font-weight: 600;

      @include for-size-large {
        font-size: 24px;
      }
    }

    p:first-child::before,
    p:last-child::after {
      content: '"';
    }
  }

  .author {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    align-items: flex-start;
    min-height: auto;

    .logoContainer {
      margin: 0;
    }

    .baseAuthorData {
      text-align: left;
      font-size: 16px;
      gap: 0;
      line-height: 1.4em;
      display: flex;
    }

    span:first-child {
      font-weight: 600;
    }
  }

  .authorImageContainer {
    border-radius: 100%;
    overflow: hidden;
    width: 100px;
    height: 100px;
    border: 4px solid currentColor;
    margin-top: -85px;
    margin-bottom: 15px;

    @include for-size-large {
      display: block;
    }
  }


  .buttonContainer {
    flex: none;
    margin-top: 20px;
  }
}

/*
  Variant: bubble (dense)
*/

.bubble.dense {
  padding: 0;

  .quotationMark {
    display: none;
  }

  &.baseBubbleContent {
    padding: 0;
    width: 100%;
  }

  &,
  .texts {
    gap: 8px;
  }

  .content {
    padding: 40px 50px;
    border-radius: 60px;
  }

  .quote {
    font-size: 15px;
    line-height: 1.6em;

    p,
    p:first-child:not(:last-child) {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }

    p:first-child::before,
    p:last-child::after {
      content: '"';
    }
  }

  .baseAuthorData {
    font-size: 14px;
  }

  .logoContainer:nth-child(2) {
    display: none;
  }
}