@import '../../../../assets/global/main';

.Hero__inner {
  @include standard-layout-column;
  display: flex;

  @include for-size-large {
    flex-direction: column;
    margin: 36px auto;
  }
}

.Hero__holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Hero__iframePopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.Hero__iframePopupOverlayCloseButton {
  appearance: none;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  align-self: flex-start;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3Cpath fill='%23fff' d='M242.7 256l100-100a31.5 31.5 0 000-44.5l-22.2-22.3a31.5 31.5 0 00-44.4 0L176 189.2 76 89.3a31.5 31.5 0 00-44.5 0L9.2 111.5a31.5 31.5 0 000 44.4l100 100.1-100 100a31.5 31.5 0 000 44.5l22.3 22.3a31.5 31.5 0 0044.4 0l100.1-100 100 100a31.5 31.5 0 0044.5 0l22.3-22.2a31.5 31.5 0 000-44.5L242.8 256z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;

  @include for-size-large {
    display: none;
  }
}

.Hero__iframePopupOverlayWrapper {
  margin: 70px;
  width: fit-content;
  height: fit-content;
  max-width: 1600px;
  max-height: 900px;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 70px);
  transform: translate(-50%, -50%);
  max-width: 160vh;

  @include for-size-large {
    margin: 40px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    transform: translate(-50%, -50%);
  }
}

.Hero__mediaSection {
  @include unselectable;

  padding-top: 15px;
  padding-bottom: 60px;

  @include for-size-large {
    padding: 16px;
  }
}

.Hero__mediaSectionImageHolder {
  box-shadow: 0px 2.1946px 5.3201px 0px rgba(67, 43, 119, 0.03), 0px 7.371px 17.869px 0px rgba(67, 43, 119, 0.04),
    0px 33px 80px 0px rgba(67, 43, 119, 0.07);
  border-radius: 8px;
  width: 1160px;
  aspect-ratio: 16/9;
}

.Hero__contentSection,
.Hero__mediaSection {
  align-items: center;
  display: flex;
  margin: 0;

  @include for-size-large {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
  }
}

.Hero__contentSection {
  padding: 15px;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 610px;
}

.Hero__buttonsRow {
  padding-top: 26px;
  padding-bottom: 16px;
  display: flex;
  gap: 32px;
  row-gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
}

.Hero__watchVideo {
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  margin: 15px 0;
  color: $brand-saturated-purple;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  line-height: 0;
  padding: 15px;
}

.Hero__textualContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Hero__title {
  color: $brand-dark-purple;
  font-weight: 500;
  line-height: 1.25em;
  margin: 0 0 15px 0;
  font-size: 44px;
  margin-bottom: 24px;
  margin-top: 54px;
  max-width: 560px;

  @include for-size-large {
    font-size: 43px;
    letter-spacing: -0.02em;
    margin-top: 24px;
    max-width: 100%;
  }
}

.Hero__text {
  @include for-size-large {
    padding-right: 0;
  }

  >p {
    margin-bottom: 4px;
  }
}

div .Hero__form {
  margin-bottom: 0px;
  align-items: normal;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0;
  margin-right: 0;

  form div:nth-child(2) {
    width: 100%;
  }

  &>div {
    padding-left: 4px;
  }
}

.Hero__smallPerksContainer {
  display: flex;
  padding-top: 16px;
  padding-bottom: 8px;

  @include for-size-large {
    flex-direction: column;
    margin-top: 4px;
  }
}

.Hero__smallPerk {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  position: relative;
  font-size: 13px;
  line-height: 1;
  color: $basic-body-color;

  @include for-size-large {
    padding-left: 15px;
  }

  &:first-of-type {
    padding-left: 0;

    @include for-size-large {
      padding-left: 15px;
    }
  }

  &:last-of-type {
    padding-right: 0;
  }

  img {
    margin-right: 6px;
  }
}


.g2RatingContainer {
  margin-bottom: 20px;
}