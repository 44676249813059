@import '~/assets/global/main';

.Container {
  display: flex;
  gap: 32px;
  align-items: center;
  max-width: 650px;
  padding: 0 12px;
  flex-direction: row;

  @include for-size-big {
    flex-direction: column;
    gap: 24px;
  }
}

.AuthorPhoto {
  display: block;
  flex-shrink: 0;
  border-radius: 100%;
  width: 120px;
  height: 120px;
}

.AuthorName {
  font-size: 22px;
  font-weight: bold;
  color: $title-body-color;
  margin: 0;
}

.AuthorRole {
  margin: 0;
  font-size: 16px;
}

.AuthorDescription {
  font-size: 14px;

  p {
    font-size: inherit;
    margin: 0 0 6px 0;
  }
}

.AuthorDetails {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0 0 10px 0;
}

.AuthorLinks {
  display: flex;
  gap: 2px;
  align-items: center;
}

.AuthorSocialLink {
  display: block;
  width: 20px;
  height: 20px;
  color: $basic-body-color;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    color: $basic-headlings-color;
  }
}