@import '~/assets/global/main';

.container {
  form {
    margin: 16px 0 !important;


    * {
      font-size: 15px !important;
      font-family: NeueFrutiger, system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji' !important;
    }

    :global(._legal-info) {
      margin-top: 16px;
      font-size: 11px !important;

      a {
        font-size: 11px !important;
        text-decoration: underline;
      }
    }

    :global(._form-label) {
      line-height: 16px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      color: $input-label !important;
      width: 100% !important;
      margin: 0 !important;
      padding-bottom: 5px !important;
    }

    :global(._field-wrapper) {

      input,
      textarea {
        border: 2px solid $input-border-color-light !important;
        background-color: $input-background-color !important;
        border-radius: 5px !important;
        transition: all 150ms ease-in-out !important;
        display: flex !important;
        padding: 10px 10px !important;
        outline: none !important;
        margin-bottom: 16px !important;

        &::placeholder {
          color: $basic-headlings-color !important;
          font-weight: 500 !important;
          opacity: 0.33 !important;
        }


        &[disabled] {
          pointer-events: none !important;
        }

        &:hover {
          border-color: $input-border-color !important;
        }

        &:active,
        &:focus-within {
          border-color: $brand-saturated-purple !important;
        }

        &[readonly] {
          background-color: $input-background-color-readonly !important;
          opacity: 0.8 !important;
        }
      }
    }
  }
}

.disableNativeButton {
  form button {
    display: none !important;
  }
}