@import '~/assets/global/main';

.sectionContent {
  max-width: 90%;
  width: 100%;

  @include for-size-big {
    max-width: none;
  }
}

.table {
  @include table-content();
  table-layout: fixed;
  margin: 0;

  thead {
    tr {
      th {
        font-size: 16px;
        text-align: center;

        @include for-size-big {
          font-size: 15px;
        }
      }
    }
  }

  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid $divider-color;
    }
  }
}

.cell {
  padding: 15px !important;

  p {
    color: $article-content-color;
    text-align: left;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    font-size: 15px;
  }

  img {
    height: 25px;
  }

  &:not(:last-child) {
    border-right: 1px solid $divider-color;
  }

  &:first-child {
    width: 25%;
  }

  &:first-child,
  &:is(th) {
    font-weight: 600;
    color: $brand-dark-purple;

    p {
      text-align: left;
      width: 100%;
      justify-content: stretch;
      font-weight: 600;
      color: $brand-dark-purple;

      >img {
        display: none;
      }
    }
  }

  @include for-size-big {
    &:not(:first-child) {
      p {
        font-size: 13px;
        flex-direction: column;
        align-items: center;
      }

      span {
        text-align: center;
      }
    }

    &:first-child {
      width: auto;
    }
  }
}