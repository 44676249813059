@import '~/assets/global/main';

.container {
  border-radius: 15px;
  border: 1px dashed $brand-saturated-purple-lighter;
  padding: 10px 16px 12px 16px;
}

.container p:last-of-type {
  margin: 0;
}