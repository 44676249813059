@import '../../../../../assets/global/main';

.BookDemoForm {
  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__fieldHolder,
  &__buttonHolder {
    position: relative;
    margin-bottom: 28px;
  }

  &__buttonHolder {
    @include for-size-large {
      display: flex;
      justify-content: center;
    }
  }

  &__textField,
  &__emailField,
  &__textareaField,
  &__selectField {
    width: 100%;
    height: 60px;
    border: 1px solid #fff;
    padding: 10px;
    color: $basic-body-color;
    box-shadow: 0 2px 4px 0 rgba(12, 0, 46, 0.3);
    border-radius: 6px;

    &:focus,
    &:active {
      outline: 0;
      -webkit-tap-highlight-color: transparent;
      box-shadow: 0 10px 14px 0 rgba(12, 0, 46, 0.06);
      border: 1px solid #f2effc;
    }

    &::placeholder {
      color: $basic-body-color;
      opacity: 1;
    }
  }

  &__textareaField {
    height: 180px;
    resize: vertical;
  }

  &__selectField {
    background: #fff;
    color: $basic-body-color;

    option {
      color: $basic-body-color;
    }
  }

  &__checkboxField {
    height: 20px;
    width: 20px;
    margin-right: 15px;
    flex-shrink: 0;
    cursor: pointer;
    margin-top: 3px;
  }

  &__label {
    display: flex;
  }

  &__checkboxFieldLabel {
    @include unselectable;
    color: $basic-body-color;
    cursor: pointer;
    display: inline-block;
  }

  &__checkboxFieldAnchor {
    color: $brand-dark-purple;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  &__button {
    @include soft-transition;
    appearance: none;
    cursor: pointer;
    background-color: $brand-yellow;
    color: $brand-dark-purple;
    font-size: 15px;
    padding: 13.5px 22px;
    border: none;
    border-radius: $button-border-radius;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include for-size-large {
      width: 100%;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $brand-dark-purple;
      color: #fff;
    }
  }

  &__labelCopy {
    position: relative;
  }

  &__formMessage,
  &__formErrorMessage {
    @extend .formMessage;
  }

  &__formErrorMessage {
    color: #ff7878;
  }

  &__buttonSpinner {
    @extend .buttonSpinner;
    width: 17px;
    height: 17px;
  }

  &__errorMessage,
  &__errorMessageCheckbox {
    @extend .formErrorMessage;
  }

  &__errorMessageCheckbox {
    bottom: -21px;
  }
}
