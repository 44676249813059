@import '~/assets/global/main';

.SectionTitle {
  @include use-predefined-background;

  padding-top: 50px;

  &__inner {
    @include standard-layout-column;
    display: flex;
    justify-content: center;
    padding: 35px 15px 50px;

    @include for-size-medium {
      padding: 24px;
    }
  }

  &__text {
    color: $brand-dark-purple;
    font-size: 36px;
    margin: 0;
    text-align: center;
    line-height: 1.4em;
    font-weight: inherit;
    font-style: inherit;
  }
}