@import '~/assets/global/main';

.Authors {
  &__standard {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1.45;
    color: $title-body-color;
  }

  &.extended {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;

    @include for-size-big {
      justify-content: center;
      flex-direction: column;
      gap: 5px;
    }

    .Authors__author {
      display: flex;
      padding-left: 10px;
      justify-content: flex-start;
      align-items: center;

      @include for-size-big {
        padding: 0;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
      }
    }

    .Authors__name {
      margin: 0 0 0 15px;
      font-size: 16px;
      font-weight: bold;
      color: $title-body-color;

      @include for-size-big {
        margin: 0;
      }
    }

    .Authors__group {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: $title-body-color;
    }

    .Authors__role {
      margin: 0;
      font-size: 16px;
      font-weight: normal;

      &::before {
        content: ', ';
      }
    }

    .Authors__image,
    img {
      border-radius: 100%;
    }
  }

  &.detailed {
    display: flex;
    margin-top: 60px;
    padding-bottom: 60px;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px dashed $divider-color;
    gap: 20px;

    &.noBorder {
      border-bottom: none;
    }

    .Authors__author {
      display: flex;
      flex-basis: 65%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include for-size-medium {
        flex-basis: 100%;
      }
    }

    .Authors__name {
      margin: 25px 0 0 0;
      font-size: 22px;
      font-weight: bold;
      color: $title-body-color;
    }

    .Authors__role {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      color: $brand-dark-purple;
      font-size: 13px;
      text-align: center;
    }

    .Authors__image,
    img {
      border-radius: 100%;
    }

    .Authors__description {
      margin-top: 30px;

      p {
        text-align: center;
        font-size: 13px;
      }

      >a {
        color: $link-color;
      }
    }
  }
}