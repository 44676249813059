@import '~/assets/global/main';

.CTASection {
  @include conditional-bottom-margin(60px);

  &__inner {
    @include standard-layout-column;
    display: flex;
    flex-direction: row;
    justify-content: center;


    @include for-size-medium {
      flex-direction: column;
      align-items: center;
      padding: 15px;
    }

    >*+* {
      margin-left: 15px;

      @include for-size-medium {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }

  &__buttonHolder {
    width: auto;
  }
}

.condensed {
  @include for-size-second-most-used {
    @include conditional-bottom-margin(40px);
  }
}