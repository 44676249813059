@import '../../assets/global/main';

.Footer {
  background-color: $neutrals-default;
  color: $text-normal;

  &__inner {
    @include standard-layout-column;
    padding: 105px 0 30px 0;

    @include for-size-large {
      padding: 50px 0;
    }
  }

  &__brandImage {
    @include unselectable;
    max-width: 120px;
  }

  &__topContainer {
    display: flex;

    @include for-size-large {
      flex-wrap: wrap;
    }
  }

  &__claimContainer {
    width: 100%;
    max-width: 250px;
    padding: 10px;

    @include for-size-large {
      max-width: 100%;
      padding: 15px;
    }
  }

  &__claim {
    font-size: 14px;
    color: $basic-body-color;
  }

  &__menuContainer {
    padding: 10px;
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;

    @include for-size-large {
      padding: 15px;
    }
  }

  &__blogContainer {
    padding: 10px;
    width: 300px;

    @include for-size-large {
      width: 100%;
      padding: 15px;
    }
  }

  &__brandImageAnchor {
    margin-bottom: 30px;
    display: block;
  }

  &__bottomContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 60px;

    @include for-size-large {
      flex-direction: column;
    }
  }

  &__socialListContainer {
    list-style: none;
    margin: 0;
    padding: 0;

    @include for-size-large {
      margin-top: 40px;
    }
  }

  &__ctaContainer {
    width: 430px;

    @include for-size-large {
      width: 100%;
      padding: 15px;
    }
  }

  &__ctaNewsletterLabel {
    font-weight: bolder;
    font-size: 18px;
  }

  ul,
  ol {
    color: $text-normal;
  }

  &__socialContainer {
    @include for-size-large {
      display: block;
      margin: 15px auto 0 auto;
    }
  }
}