@import '../../assets/global/main';

.MobileContentMenu {
  &__menu {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
  }

  &__menuListElement {
    display: flex;
    justify-content: center;
  }

  &__menuItemAnchor {
    display: flex;
    width: 100%;
    margin: 7.5px 0;
  }

  &__menuItemLabel {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 24px;
    font-size: 14px;
    line-height: 1;
    color: $brand-dark-purple;
    gap: 8px;
  }
}

.MobileMenuItemBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 9px;
  background-color: $brand-yellow;
  border-radius: 12px;
  padding: 0 8px;
  text-transform: uppercase;
  color: white;
  min-height: 18px;
}
