@import '~/assets/global/main';

.PostListArticle {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  padding-bottom: 90px;
  overflow: hidden;

  &.two {
    flex-basis: 50%;
    max-width: 50%;
  }

  &.three {
    flex-basis: 33%;
    max-width: 33%;
  }

  &.two,
  &.three {
    @include for-size-medium {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  &__inner {
    position: relative;
  }

  &__imageContainer {
    @include unselectable;
    display: flex;
    position: relative;
    z-index: 0;
    justify-content: center;
  }

  &__image {
    object-fit: contain;
  }

  &__title {
    @include soft-transition;
    @include word-wrap;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.45;
    margin: 30px 0 15px 0;
    color: $title-body-color;
  }

  &__categoryName {
    @include soft-transition;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.54;
    margin-bottom: 10px;
    color: $brand-saturated-purple;
    margin: 25px 0 20px;

    ~.PostListArticle__title {
      margin-top: 0;
    }
  }

  &__excerpt {
    margin-bottom: 20px;
  }

  &__readMore {
    display: inline-flex;
    font-size: 13px;
    align-items: center;
    color: $brand-dark-purple;

    &::after {
      @include soft-transition;
      position: absolute;
      bottom: 0;
      left: 0;
      content: ' ';
      display: block;
      height: 1px;
      background-color: $brand-dark-purple;
      width: 0;
      max-width: 110px;
    }
  }

  &__readMoreIcon {
    @include soft-transition;
    margin-left: 5px;
    display: inline-flex;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cdefs/%3E%3Cpath fill='%23401e58' d='M314 216H12a12 12 0 00-12 12v56a12 12 0 0012 12h302v46a24 24 0 0041 17l86-86a24 24 0 000-34l-86-86a24 24 0 00-41 17v46z'/%3E%3C/svg%3E");
    height: 14px;
    width: 19px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__imageWrapper {
    position: relative;
    display: flex;
  }

  &__linkWrapp {
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    text-indent: 200%;
    white-space: nowrap;

    // Pass hover state to siblings
    &:hover,
    &:active {
      ~.PostListArticle__title {
        color: $brand-dark-purple;
      }

      ~.PostListArticle__readMore {
        >span {
          margin-left: 15px;
        }

        &:after {
          width: 100%;
        }
      }
    }
  }
}