@import '~/assets/global/main';



.questionContainer {
  appearance: none;
  padding: 0;
  border: none;
  border-bottom: 1px solid $divider-color;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.questionContainer.expanded {
  .answer {
    height: auto;
    max-height: 150px;
    transition-timing-function: ease-in;
    transition: max-height 0.2s, padding 0.2s;
    padding: 16px 0;
  }

  .icon {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='17' height='17' viewBox='0 0 17 17'%3E%3Cg%3E%3C/g%3E%3Cpath d='M15 8v1h-13v-1h13z' fill='%23576370' /%3E%3C/svg%3E");
  }
}

.question {
  font-size: 18px;
  color: $text-normal;
  background-color: $article-background-color;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

}

.icon {
  height: 40px;
  width: 40px;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='17' height='17' viewBox='0 0 17 17'%3E%3Cg%3E%3C/g%3E%3Cpath d='M16 9h-7v7h-1v-7h-7v-1h7v-7h1v7h7v1z' fill='%23576370' /%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.answer {
  justify-content: flex-start;
  color: $text-normal;
  max-height: 0;
  overflow: hidden;
  font-size: 15px;
  text-align: left;
  margin-left: 15px;
  transition-timing-function: ease-in;
  transition: max-height 0.2s, padding 0.2s;

  a {
    color: $brand-saturated-purple;
    font-weight: 600;
  }

  &>p {
    color: $text-normal;
  }
}