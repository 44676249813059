@import '~/assets/global/main';

.radioLabel {
  margin-left: 8px;
  cursor: pointer;
  font-size: 14px;
  color: $text-normal;
}

.radioOptionContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 2px 0;
}

.radioOptionInput {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.radioOptionCheckmarkContainer {
  width: 20px;
  height: 20px;
  border: 2px solid $border-dark;
  border-radius: 100%;

  background-color: white;
  position: relative;
  flex: none;
  cursor: pointer;
}

.radioOptionCheckmark {
  width: 10px;
  height: 10px;
  background: $brand-saturated-purple;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 200ms ease-in-out;

  &[data-checked='true'] {
    transform: translate(-50%, -50%) scale(1);
  }
}