@import '../../../../../assets/global/main';

.SidebarRecentArticle {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  &__inner {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__imageContainer {
    @include unselectable;
    display: flex;
    height: 69px;
    width: 85px;
  }

  &__image {
    object-fit: contain
  }

  &__title {
    @include soft-transition;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    margin: 0;
    color: $title-body-color;
  }

  &__linkWrapp {
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    text-indent: 200%;
    white-space: nowrap;
  }

  &__imageColumn {
    display: flex;
    width: 100px;
    margin-right: 25px;
  }

  &__metaColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__date {
    font-size: 14px;
    color: #85859c;
    font-weight: 300;
  }
}