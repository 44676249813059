@import '~/assets/global/main';

.container {
  @include conditional-bottom-margin();
}

.inner {
  @include standard-layout-column;
  padding: 25px 0;
}

.title {
  padding: 0;

  h3 {
    font-size: 26px;
  }
}

.logotypeList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px 0;

  @include for-size-large {
    max-width: 700px;
    margin: 0 auto;
    gap: 30px 10px;
  }

  @include for-size-big {
    gap: 25px 10px;
  }
}

.logotypeListElement {
  @include unselectable;
  height: 40px;
  width: 210px;
  justify-content: center;
  align-items: center;
  display: flex;

  @include for-size-xtralarge {
    width: 165px;
  }

  @include for-size-large {
    width: 140px;
  }

  @include for-size-medium {
    width: 45%;
  }
}