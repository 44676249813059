@import '~/assets/global/main';

.container {
  @include standard-layout-column;
  padding: 0 15px;

  @include for-size-big() {
    padding: 0;
  }
}

.table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 14px;
  position: relative;

  @include for-size-medium() {
    font-size: 11px;
  }

  @include for-size-big() {
    grid-template-columns: 1.5fr repeat(3, 1fr);
  }
}

.tableHeader {
  background-color: $brand-pastel-purple;
  grid-column-start: span 4;
  padding: 12px 0 12px 16px;
  min-height: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: $basic-body-color;
}

.featureNameCell {
  font-weight: bold;
  padding: 8px 8px 8px 16px;
  color: $text-normal;
  min-height: 64px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-medium;
}

.tooltipContainer {
  @include for-size-medium() {
    justify-content: space-between;
  }
}

.tooltip {
  @include for-size-large() {
    left: 0;
    transform: none;
    max-width: 60vw;
    min-width: 240px;
  }
}