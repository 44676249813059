@import '~/assets/global/main';

$yellow-button-font-color: $brand-dark-purple;
$yellow-button-font-color-hover: #fff;

$yellow-button-background-color: $brand-yellow;
$yellow-button-background-color-hover: $brand-dark-purple;

$transparent-button-font-color: $brand-dark-purple;
$transparent-button-font-color-hover: $brand-dark-purple;

$transparent-button-background-color: transparent;
$transparent-button-background-color-hover: transparent;

$purple-button-font-color: #fff;
$purple-button-font-color-hover: $brand-dark-purple;

$purple-button-background-color: $brand-dark-purple;
$purple-button-background-color-hover: transparent;

.RoundedButton {
  @include soft-transition;
  display: flex;

  border-radius: $button-border-radius;
  align-self: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;

  // If button contain icon + span with label, add margin.
  >*+* {
    margin-left: 8px;
  }

  // Backgrounds
  @include use-predefined-background;

  &.Yellow {
    border: 2px solid $brand-yellow;
    font-weight: 600;
    background-color: $brand-yellow;
    color: $brand-dark-purple;

    &:hover,
    &:active {
      background-color: $brand-dark-purple;
      color: #fff;
      border-color: $brand-dark-purple;
    }

    &.withBorder {

      &:hover,
      &:active {
        border-color: #fff;
      }
    }
  }

  &.Transparent {
    border-radius: 5px;

    &:hover,
    &:active {
      background-color: $brand-pastel-purple;
    }
  }

  &.DarkPurple {
    border: 2px solid $brand-dark-purple;

    &:hover,
    &:active {
      background-color: $brand-yellow;
      color: $brand-dark-purple !important;
      border: 2px solid $brand-dark-purple;
    }

    &.withBorder {
      background-color: transparent;
      color: $brand-dark-purple !important;

      &:hover,
      &:active {
        background-color: rgba($brand-dark-purple, 0.1);
        border-color: $brand-dark-purple;
      }
    }
  }

  &.LightPurple {
    font-weight: 600;
    border: 2px solid $brand-saturated-purple;

    &:hover,
    &:active {
      background-color: $brand-saturated-purple-lighter;
      border: 2px solid $brand-saturated-purple-lighter;
    }

    &.withBorder {
      background-color: transparent;
      color: $brand-saturated-purple !important;

      &:hover,
      &:active {
        background-color: rgba($brand-saturated-purple, 0.1);
        border-color: $brand-saturated-purple;
      }
    }
  }

  // Sizes
  &.Regular {
    font-size: 15px;
    padding: 11.5px 22px;
  }

  &.Big {
    font-size: 18px;
    padding: 18px 38px;
  }

  &.Small {
    font-size: 14px;
    padding: 7.5px 18px;
  }

  &__label {
    font-size: inherit;
    display: inline-flex;
    line-height: 1;
    align-items: center;
    min-height: 20px;
  }

  &__icon {
    position: relative;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}