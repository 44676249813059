@import '~/assets/global/main';

.CookieConsent {
  @include unselectable;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 -1px 10px 0 rgb(172, 171, 171, 0.3);
  color: $basic-body-color;

  &__inner {
    @include standard-layout-column;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include for-size-large {
      flex-direction: column;
    }
  }

  &__formula {
    line-height: 1.2;
    font-size: 12px;
    display: inline-block;
    padding: 20px 10px;

    @include for-size-large {
      padding: 8px 8px;
    }
  }

  &__anchor {
    display: inline-flex;
    padding: 3px;
    text-decoration: underline;
  }

  &__button {
    @include soft-transition;
    appearance: none;
    cursor: pointer;
    background-color: $brand-yellow;
    border: none;
    padding: 15px;
    line-height: 1;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin: 10px;
    border-radius: $button-border-radius;
    max-height: 30px;
    color: $brand-dark-purple;

    &:hover,
    &:focus,
    &:active {
      background-color: $brand-dark-purple;
      color: #fff;
    }
  }
}