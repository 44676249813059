@import '~/assets/global/main';

.Content {
  display: block;
  width: 100%;
  outline: none;
  font-size: 15px;
  color: inherit;
  font-weight: inherit;
  padding: 9px 10px;
  border-radius: 3px;
  border: 2px solid $input-border-color-light;
  border-radius: 5px;
  transition: all 150ms ease-in-out;

  &[disabled] {
    pointer-events: none;
  }

  &:hover {
    border-color: $input-border-color;
  }

  &:active,
  &:focus-within {
    border-color: $brand-saturated-purple
  }

  ::placeholder {
    color: $text-normal;
    font-weight: 500;
    opacity: 0.33;
  }
}

.Error {
  color: $error-color;
  font-weight: 500;
  font-size: 13px;
}

.Label {
  font-size: 13px;
  font-weight: 500;
  color: $text-normal;
  width: 100%;
  margin: 0;
  padding-bottom: 5px;
}