@import '~/assets/global/main';

.container {
  margin-bottom: 60px;
}

.title {
  color: $brand-dark-purple;
  font-size: 20px;
  margin-bottom: 20px;
}

.postsHolder {
  display: flex;
  flex-direction: column;
}

.listContainer {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listElement {
  margin: 8px 0;
}

.listLabel {
  font-size: 14px;
  color: $brand-dark-purple;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}