@import '~/assets/global/main';


.container {
  @include standard-layout-column;
  padding: 0 15px;

  @include for-size-xtralarge() {
    padding: 0;
  }

  @include for-size-large() {
    padding: 0 15px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -26px;
  margin-bottom: 10px;
  min-height: 94px;

  @include for-size-big() {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 12px;
    margin-top: 0;
  }
}

.title {
  display: flex;
  align-items: baseline;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.titleWrapper {
  display: flex;
  align-items: center;

  @include for-size-large() {
    flex-direction: column;
    align-items: flex-start;
  }
}

.priceTaxInfo {
  margin-left: 16px;
  color: $basic-body-color;
  font-weight: 400;
  font-size: 13px;
  margin-top: 4px;


  @include for-size-large() {
    margin-left: 0;
  }
}

.plansGrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 60px;
  justify-content: stretch;

  @include for-size-xtralarge() {
    column-gap: 8px;
  }

  @include for-size-large() {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
  }

  @include for-size-big() {
    grid-template-columns: minmax(0, 1fr);
    gap: 24px;
  }
}

.radioGroup {
  font-weight: 600;
  background-color: $section-highlight;
  padding: 9px 16px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.discount {
  color: $brand-saturated-purple;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 4px;
  padding: 0 4px;

  @include for-size-small() {
    display: block;
    margin: 0;
    padding: 0;
  }
}

.comparePlansButton {
  width: fit-content;
  border: none !important;
  margin: 110px auto 0 auto;

  @include for-size-big() {
    margin: 60px auto 0 auto;
  }
}

.comparePlansButtonLabel {
  display: flex;
  align-items: center;
  gap: 6px;
}

.comparePlansButtonLabelIcon {
  width: 18px;
  height: 18px;
}