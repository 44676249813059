@import '~/assets/global/main';

.stickyBar {
  @include use-predefined-background;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.12);
  z-index: 25;
  display: flex;
  align-items: center;

  &.DarkPurple,
  &.LightPurpleGradient,
  &.LightPurple {

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }
  }

  &.Yellow {

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $brand-dark-purple;
    }
  }

}

.disableOnMobile {
  display: none;

  @media screen and (min-width: 992px) {
    display: flex;
  }
}

.disableOnDesktop {
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.content {
  @include standard-layout-column;

  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  pointer-events: auto;
}

.close {
  margin-right: 12px;
  cursor: pointer;
  background: none;
  border: none;
  width: 48px;
  height: 48px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .stickyBar.LightPurpleGradient &,
  .stickyBar.LightPurple &,
  .stickyBar.DarkPurple & {
    filter: brightness(10);
  }
}