@import '../../../../assets/global/main';

.IntegrationsList {
  margin-bottom: 60px;

  &__inner {
    @include standard-layout-column;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    gap: 40px;
    padding: 20px 40px;
    max-width: 1100px !important;

    @include for-size-large() {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
    }

    @include for-size-big() {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    border: 1px solid transparent;
    box-shadow: 1px 11px 31px $shadow-color;
    border-radius: 10px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 1px 11px 31px $hover-shadow-color;
    }
  }

  &__title {
    font-size: 20px;
    color: $brand-dark-purple;
    text-align: center;
    margin-top: 20px;
  }

  &__description {
    font-size: 15px;
    color: $basic-body-color;
    text-align: center;
    margin-bottom: 20px;
    flex: 1;

    &:empty {
      margin: 0;
    }
  }

  &__button {
    max-width: 140px;
    margin-top: 20px;
  }

  &__comingSoon {
    font-size: 15px;
    color: $brand-pastel-purple-darker;
    text-align: center;
    background: $brand-pastel-purple;
    margin-top: 20px;
    padding: 12px 20px;
    border-radius: 1000px;
    font-weight: 600;
  }
}
