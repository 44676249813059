@import '~/assets/global/main';

.container {
  padding: 8px 0 8px 16px;
  color: $text-normal;
  min-height: 64px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-medium;
}

.icon {
  width: 24px;
  height: 24px;

  @include for-size-medium() {
    width: 18px;
    height: 18px;
  }
}

.checkIcon {
  color: $success-color;
}

.crossIcon {
  color: $error-color;
  width: 12px;
  height: 12px;

  @include for-size-medium() {
    width: 9px;
    height: 9px;
  }
}

.minusIcon {
  color: $border-medium;
}