@import '../../../../assets/global/main';

.EmptyCategory {
  margin: 0 auto;

  &__inner {
    @include standard-layout-column;
    padding: 150px;
    color: $basic-body-color;

    @include for-size-medium {
      padding: 150px 15px;
    }
  }

  &__div {
    font-weight: bold;
    text-align: center;

    > h1 {
      color: $basic-body-color;

      @include for-size-medium {
        font-size: 24px;
      }
    }
  }
}
