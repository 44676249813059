@import '~/assets/global/main';

.container {
  margin-bottom: 0;

  &:not(:last-child)>div {
    padding-bottom: 0;
  }

  &:last-child {
    margin-bottom: 80px;

    &.noBottomMargin {
      margin-bottom: 0;
    }
  }
}

.header {
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  gap: 8px;
  border-bottom: 1px solid $divider-color;

  h3 {
    margin: 0;
    font-size: 24px;
    color: $brand-dark-purple;
  }

  &.expanded {
    border-color: transparent;

    img {
      transform: rotate(180deg);
      width: 20px;
    }
  }
}