@import '../../../../assets/global/main';

.BookDemo {
  margin-bottom: 60px;

  &__inner {
    @include standard-layout-column;
    display: flex;

    @include for-size-large {
      flex-direction: column;
    }
  }

  &__content {
    display: flex;
    width: 45%;
    padding: 15px;
    flex-direction: column;

    @include for-size-large {
      width: 100%;
    }
  }

  &__form {
    flex: 1 1 auto;
    padding: 15px;

    @include for-size-large {
      width: 100%;
    }
  }

  &__title {
    margin-top: 0;
  }
}