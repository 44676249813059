@import '../../../../assets/global/main';

.MetaPost {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include for-size-big {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__divider {
    @include for-size-big {
      display: none;
    }
  }

  &.standard {
    margin-bottom: 20px;
    gap: 5px;
  }

  &.extended {
    margin: 40px 0;
    padding-left: 10px;
    gap: 10px;

    @include for-size-big {
      gap: 0;
    }
  }
}
