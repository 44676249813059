@import '~/assets/global/main';

.sectionContent {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  align-items: center;
  justify-content: center;
}

.sectionTitle {
  max-width: 65%;
  padding-top: 0;

  @include for-size-large {
    max-width: 100%;
  }
}

.options {
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;

  .option {
    min-width: 200px;
  }

  @include for-size-large {
    flex-direction: column;
    grid-template-columns: 1fr;
    row-gap: 15px;

    .option {
      min-width: 100%;
    }
  }
}

.content {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  max-width: 100%;

  @include for-size-large {
    grid-template-columns: 1fr;
    row-gap: 15px;
  }
}

.comparisonBox {
  padding: 20px 25px;
  text-align: left;
  border: 1px solid $brand-pastel-purple-darker;
  background-color: $section-light-gray-background;
  border-radius: 20px;

  li {
    list-style: none;
    padding-left: 15px;
    display: flex;
    padding-bottom: 13px;
    font-size: 17px;

    &:before {
      content: '';
      background-image: url('~/public/assets/icons/x-circle.svg');
      font-weight: 700;
      width: 24px;
      height: 24px;
      position: absolute;
      margin-left: -35px;
      margin-top: 2px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &:last-child li:before {
    background-image: url('~/public/assets/icons/check-circle.svg');
  }
}

.comparisonTitle {
  font-size: 20px;
  font-weight: 600;
  color: $brand-dark-purple;
  text-align: center;
  margin-bottom: 25px;
}