@import '~/assets/global/main';

.rowContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.rating {
  font-weight: 600;
}

.logo {
  margin-bottom: 6px;
}

.ratingParagraph {
  font-size: 13px;
  color: $brand-dark-purple;
  margin: 0;
  transition: color 0.3s;

  &:hover {
    color: $brand-purple-hover;
  }
}

.starsContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 2px;
}