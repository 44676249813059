@import '../../../assets/global/main';

.SignupForm {
  @include standard-layout-column;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;

  @include for-size-large {
    align-items: normal;
  }

  &__inner {
    padding: 10px 0 15px 0;
    display: flex;
    justify-content: center;
    width: 100%;

    @include for-size-large {
      justify-content: normal;
    }

    @include for-size-big {
      flex-direction: column;
    }
  }

  &__emailInput {
    height: 45px;
    border: 1px solid $input-border-color;
    border-radius: 1000px;
    background-color: $input-background-color;
    width: 100%;
    padding: 10px 18px;
    margin-right: 15px;

    @include for-size-big {
      margin-right: 0;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }

    &::placeholder {
      color: hsla(240, 7%, 50%, 1);
    }
  }

  &__inputContainer {
    position: relative;
    margin-right: 8px;
    width: 100%;
    max-width: 260px;

    @include for-size-large {
      max-width: 415px;
    }

    @include for-size-big {
      margin-right: 0;
      max-width: 100%;
    }
  }

  &__buttonContainer {
    @include for-size-big {
      margin-top: 20px;
    }
  }

  &__buttonInput {
    @include for-size-big {
      width: 100% !important;
    }
  }

  &__errorMessage {
    @extend .formErrorMessage;
  }

  &__smallPerksContainer {
    display: flex;
    padding-top: 0;
  }
}