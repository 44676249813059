@import '~/assets/global/main';

.Pagination {
  @include unselectable;
  padding: 0 15px;

  &__listGroup {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

    @include for-size-medium {
      justify-content: center;
    }
  }

  &__listElement {
    display: inline-flex;
    margin: 6px;
  }

  &__listAnchor {
    background-color: #fbfbfd;
    color: #677294;
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #e7e7f6;
    justify-content: center;
    align-items: center;

    &:hover,
    &:active {
      color: $brand-dark-purple;
      border-color: $brand-dark-purple;
    }

    &.isLeftArrow,
    &.isRightArrow {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cdefs/%3E%3Cpath fill='%23677294' d='M16 9H3.2L9.4 15l-.8.7-7.3-7.3L8.6 1l.8.7L3.2 8H16v1z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      text-indent: 200%;
      overflow: hidden;

      &:hover,
      &:active {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cdefs/%3E%3Cpath fill='%23422c58' d='M16 9H3.2L9.4 15l-.8.7-7.3-7.3L8.6 1l.8.7L3.2 8H16v1z'/%3E%3C/svg%3E");
      }
    }

    @include for-size-medium {
      height: 30px;
      width: 30px;
    }

    &.isRightArrow {
      transform: rotate(180deg);
    }

    &.isCurrent {
      background-color: $brand-dark-purple;
      color: #fff;
      border-color: transparent;
    }

    &.isDisabled {
      cursor: not-allowed;
    }
  }
}