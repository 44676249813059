@import '~/assets/global/main';

.wrapper {
  padding: 0 16px;
  @include standard-layout-column;
}

.container {
  background-color: $brand-saturated-purple;
  border-radius: 8px;
  color: #fff;
  margin-right: 64px;
  display: flex;

  @include for-size-large {
    margin-right: 0;
  }
}

.content {
  padding: 56px 0 80px 144px;
  flex-basis: 57.5%;
  display: flex;
  flex-direction: column;

  @include for-size-xtralarge {
    padding: 56px 0 80px 80px;
  }

  @include for-size-large {
    padding: 48px;
    flex-basis: 100%;
  }

  @include for-size-medium {
    padding: 32px;
  }
}

.screenshots {
  flex-basis: 42.5%;
  position: relative;

  @include for-size-large {
    display: none;
  }
}

.title {
  font-size: 28px;
  font-weight: normal;
  color: #fff;
  white-space: nowrap;
  line-height: 1.5;

  @include for-size-large {
    white-space: normal;
    font-size: 24px;
  }
}

.description p {
  font-size: 17px;
  color: #fff;
  line-height: 1.5;
  margin-top: 12px;
  margin-bottom: 42px;

  @include for-size-large {
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 24px;
  }
}

.buttonsContainer {
  display: flex;
  gap: 18px;

  @include for-size-large {
    flex-wrap: wrap;
  }
}

.ctaButton {
  padding: 10px 48px;
  outline: none;
  border-radius: $button-border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  @include soft-transition;
}

.ctaButton.purple {
  border: 2px solid #fff;
  color: #fff;

  &:hover {
    background-color: #fff;
    color: $brand-saturated-purple;
  }
}

.ctaButton.yellow {
  background-color: $brand-yellow;
  color: $brand-saturated-purple;

  &:hover {
    background-color: $brand-yellow-lighter;
  }
}

.screenshot {
  box-shadow: 0px 0px 10px 0px rgba(33, 25, 44, 0.2);
  border-radius: 6px;
}

.webappScreenshot {
  position: absolute;
  right: -80px;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
}

.overlayScreenshot {
  position: absolute;
  bottom: 7.5%;
  left: 0;
  width: 27.5%;
  height: auto;
}