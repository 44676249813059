@import '../../../../assets/global/main';

.PageHero {
  margin: 50px 0;

  &__inner {
    @include standard-layout-column;
    display: flex;
    align-items: center;

    @include for-size-large {
      flex-direction: column;
    }
  }

  &__content,
  &__media {
    width: 50%;

    @include for-size-large {
      width: 100%;
    }
  }

  &__content {
    padding: 15px 30px 15px 15px;

    @include for-size-large {
      padding: 15px;
    }
  }

  &__media {
    @include unselectable;
    padding: 15px 15px 15px 30px;

    @include for-size-large {
      padding: 15px;
      justify-content: center;
      display: flex;
    }
  }

  &__contentCTA {
    max-width: 150px;
  }
}
