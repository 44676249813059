@import '../../../../assets/global/main';

.CaseStudyList {
  margin-bottom: 60px;

  &__inner {
    @include standard-layout-column;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 40px;
    padding-bottom: 80px;
  }

  &__singleArticle {
    border: 1px solid transparent;
    box-shadow: 1px 11px 31px $shadow-color;
    border-radius: 10px;
    width: 100%;
    max-width: 340px;
    margin: 10px;
    padding: 20px 20px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__singleArticleTitle {
    font-size: 24px;
    color: $brand-dark-purple;
    text-align: center;
    margin-top: 20px;
  }

  &__singleArticleExcerpt {
    font-size: 15px;
    color: $basic-body-color;
    text-align: center;
    margin-bottom: 30px;
  }

  &__singleArticleButtonHolder {
    max-width: 140px;
    margin: 0 auto;
  }
}