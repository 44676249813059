@import '~/assets/global/main';

.wrapper {
  padding: 56px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
}

.light-purple {
  background-color: $article-background-color;
}

.white {
  background-color: transparent;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 550px;
  margin: 0 auto;
  width: 100%;
}

.title {
  line-height: 1.25em;
  margin: 0;
}

.regular {
  font-size: 28px;
  font-weight: 400;
  color: $text-normal;

  @include for-size-medium {
    font-size: 24px;
  }
}

.big {
  font-weight: bold;
  font-size: 42px;
  color: $brand-dark-purple;


  @include for-size-medium {
    font-size: 32px;
  }
}

.description {
  color: $basic-body-color;
  line-height: 1.5;
  margin: 0;
}

.bottomText {
  color: $basic-body-color;
  line-height: 1.5;
  font-weight: bold;
  margin: 0;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  width: 100%;

  @include for-size-medium {
    flex-direction: column;
  }
}

.emailInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: 60%;
  width: 100%;
  gap: 6px;
}

.emailInput {
  height: 45px;
  border: 1px solid $brand-saturated-purple;
  border-radius: 45px;
  background-color: transparent;
  padding: 10px 18px;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    color: rgba(118, 118, 136, 1);
  }
}

.submitButton {
  height: 45px;
  border: 1px solid $brand-saturated-purple;
  color: $brand-saturated-purple;
  border-radius: 45px;
  background-color: transparent;
  padding: 10px 48px;
  flex-basis: 40%;
  font-weight: bold;
  @include soft-transition;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus,
  &:active {
    background-color: $brand-saturated-purple;
    color: white;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.errorMessage {
  color: $error-color;
  font-weight: bold;
}

.successMessage {
  color: $success-color;
  font-weight: bold;
}