@import '~/assets/global/main';

.container {
  @include standard-layout-column;
  display: flex;
}

.container.video {
  border: 2px solid $brand-dark-purple;

  >div {
    width: 100%;
    height: 100%;
  }
}

.container.image {
  width: 100%;
  height: 100%;
}