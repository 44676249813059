@import '~/assets/global/main';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #F4F3FB;
  padding: 8px 16px 7px;
  gap: 10px;

  >img {
    flex: none;
    cursor: pointer;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-left: 28px;

  @include for-size-big {
    padding: 0;
  }

  >p {
    margin: 0;
    color: $basic-headlings-color;
    font-size: 13px;
  }

  a {
    color: $brand-saturated-purple;
    margin-left: 5px;

    &:hover {
      color: $brand-saturated-purple-lighter;
    }
  }
}