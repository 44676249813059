@import '~/assets/global/main';

.wrapper {
  @include use-predefined-background;
}

.content {
  @include standard-layout-column;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 64px 12px;
}

.details {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.imageContainer {
  position: relative;
  width: 128px;
  min-height: 128px;
  height: auto;
  margin-bottom: 32px
}

.image {
  object-fit: contain;
  object-position: center;
}

.title {
  color: $brand-dark-purple;
  font-weight: 600;
  line-height: 1.25em;
  font-size: 48px;
  text-align: center;
  margin: 0;

  @include for-size-large {
    font-size: 43px;
    letter-spacing: -0.02em;
  }
}

.subtitle {
  color: $brand-dark-purple;
  margin: 36px 0;
}

.description {
  margin-bottom: 32px;
  color: $brand-dark-purple;

  * {
    font-size: 18px;
  }
}