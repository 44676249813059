@import '../../../../assets/global/main.scss';

.JobOffersList {
  &__container {
    max-width: $regular-content-max-width;
    padding: 15px 15px 60px;
    margin-left: auto;
    margin-right: auto;
  }

  &__list {
    padding-left: 0px;
  }

  &__item {
    list-style: none;
    border-bottom: 1px solid #d7d7d7;
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 18px;
    margin: 0;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  &__location,
  &__salary {
    color: #9b9fa5;
    font-weight: lighter;
  }

  &__salary {
    padding-left: 10px;
    @include for-size-medium {
      padding-left: 0px;
    }
  }

  &__detailsLink {
    span {
      text-transform: uppercase;
      font-size: 13px;
    }
  }

  &__contentWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-basis: 100%;
  }

  &__contentColumn {
    flex-basis: 50%;
    @include for-size-medium {
      flex-basis: 100%;
    }
  }
}
