@import '~/assets/global/main';

.container {
  position: absolute;
  bottom: 100px;
  right: 50px;

  div {
    width: 10px;
    height: 10px;
    background: $brand-yellow;
  }

  div:nth-child(1) {
    transform: translate(10px, -10px) scale(1.5);
  }

  div:nth-child(2) {
    transform: translate(-15px, -2px) scale(1);
  }

  div:nth-child(3) {
    transform: translate(-35px, 28px) scale(1.5);
  }

  div:nth-child(4) {
    transform: translate(-40px, -10px) scale(1.5);
    background: $brand-saturated-purple;
  }

  div:last-child {
    transform: scale(3);
    background: $brand-saturated-purple;
  }
}