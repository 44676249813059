@import '~/assets/global/main';

.planContainer {
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 32px;
  border: 2px solid $border-medium;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  height: 100%;

  @include for-size-big() {
    padding: 32px;
    gap: 28px;
  }
}

.primaryBorder {
  border: 2px solid $brand-soft;
}

.outerWrapper {
  padding: 2px;
  padding-bottom: 0;
  margin: -2px;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
}

.badge {
  position: absolute;
  top: 22px;
  right: -49px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: $brand-yellow;
  transform: rotate(45deg);
  text-transform: uppercase;
  width: 180px;
}

.badgeText {
  margin: 0;
  color: white;
  font-size: 12px;
  line-height: 37px;
  font-weight: bold;
  transform: translateY(1px);
}

.planTitle {
  font-size: 22px;
  height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $brand-saturated-purple;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
}

.planDescription {
  font-size: 14px;
  color: $basic-body-color;
  line-height: 1.5;
  margin: 0;
}

.planPrice {
  font-size: 46px;
  color: $brand-dark-purple;
  font-weight: 500;
  letter-spacing: -1px;
  margin: 0;
  line-height: 1;
  margin-bottom: 8px;
}

.featuresList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 0;
}

.featuresTitle {
  color: $text-normal;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.featuresSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;

  @include for-size-big() {
    margin-top: 0;
  }
}

.mainFeaturesList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 0;
}

.manageSubscriptionButton {
  height: 50px;
  border-radius: 100px;
  width: 100%;

  span {
    font-size: 20px;
    font-weight: bold;
    line-height: 18px;
  }
}