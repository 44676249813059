@import '../../assets/global/main';
$main-nav-container-box-shadow: 0 4px 6px 0 rgba(12, 0, 46, 0.06);

.MainNavBar {
  border-bottom: 1px solid $line-light-color;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;

  &__inner {
    @include standard-layout-column;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include for-size-xtralarge {
      justify-content: flex-end;
    }
  }

  &__brandAnchor,
  &__brandImage {
    width: 100px;
    @include unselectable;
    display: block;
  }

  &__brandContainer {
    width: 220px;
    display: flex;
    align-items: center;

    @include for-size-xtralarge {
      width: 100px;
      margin-right: 15px;
    }
  }

  &__menuContainer {
    display: flex;
    max-width: 650px;

    @include for-size-large {
      display: none;
    }

    @include for-size-xtralarge {
      justify-content: flex-end;
    }
  }

  &__ctaContainer {
    display: flex;
    flex-direction: row;
    margin-right: -15px;
    margin-left: 15px;

    // lobotomized owl selector
    >*+* {
      margin-left: 15px;
    }

    @include for-size-large {
      display: none;
    }

    @include for-size-xtralarge {
      margin-right: 15px;
    }
  }

  &__menuContainer {
    flex: 1;
  }

  &__mobileMenuButtonContainer {
    flex: 1;
    width: 40px;
    justify-content: flex-end;
    align-items: center;
    display: none;

    @include for-size-large {
      display: flex;
    }
  }

  &__mobileMenuButton {
    appearance: none;
    height: 40px;
    width: 40px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23401e58' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-menu'%3E%3Cline x1='3' y1='12' x2='21' y2='12'%3E%3C/line%3E%3Cline x1='3' y1='6' x2='21' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='18' x2='21' y2='18'%3E%3C/line%3E%3C/svg%3E");
    border: none;
    background-color: #fff;
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;

    &:hover,
    &:active {
      cursor: pointer;
      background-color: $brand-light-gray;
    }

    &.isActive {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23401e58' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
    }
  }

  &__mobileMenuContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;

    @media screen and (min-width: 991px) {
      display: none;
    }
  }

  &__mobileSingleButtonMenu {
    margin-left: auto;

    @media screen and (min-width: 991px) {
      display: none;
    }
  }

  &__mobileMenuButtons {
    display: flex;

    // lobotomized owl selector
    >*+* {
      margin-left: 15px;
    }
  }
}