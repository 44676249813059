@import '~/assets/global/main';

.SubmitButton {
  @include soft-transition;
  appearance: none;
  cursor: pointer;
  background-color: $brand-yellow;
  color: $brand-dark-purple;
  font-size: 15px;
  padding: 13.5px 22px;
  border: none;
  border-radius: $button-border-radius;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include for-size-large {
    width: 100%;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $brand-dark-purple;
    color: #fff;
  }
}

.SubmitButtonSpinner {
  @extend .buttonSpinner;
  width: 17px;
  height: 17px;
}

.FieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.PrivacyPolicyLink {
  color: $brand-dark-purple;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.PrivacyPolicyInfo {
  font-size: 13px;
  color: $basic-body-color;
  display: inline-block;
  margin: 10px 0;
}

.ResponseMessage {
  margin-top: 8px;
  font-weight: 500;
}

.ErrorMessage {
  color: $error-color;
}

.SuccessMessage {
  color: $success-color;
}