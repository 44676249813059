@import '~/assets/global/main';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  padding: 12px 26px 16px 35px;
  overflow: hidden;
  background-color: #fff;
  margin: 48px 0;
  box-shadow: 0px 14px 48px -2px rgba(67, 43, 119, 0.18),
    0px 7px 16px -2px rgba(67, 43, 119, 0.1),
    0px 2px 4px -2px rgba(67, 43, 119, 0.04);
}

.container p:last-of-type {
  margin: 0;
}

.container .title p {
  font-size: 1.35rem;
  color: black;
}

.container .details p {
  font-size: 1.025rem;
  color: $article-content-color;
}


.colorIndent {
  position: absolute;
  width: 15px;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $brand-saturated-purple-lighter;
}

.ctaButton {
  background-color: $brand-saturated-purple;
  width: fit-content;
  color: #fff;
  border-radius: 20px;
  padding: 4px 40px;
  margin-top: 18px;
  @include soft-transition;
}

.container .ctaButton p {
  font-size: 1.025rem;
  color: #fff;
}

.container .ctaButton:hover {
  background-color: $brand-saturated-purple-lighter;
  text-decoration: none;
}